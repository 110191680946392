<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import test from "@/app.config.json";
  import axios from "axios";
  import CKEditor from "@ckeditor/ckeditor5-vue";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  import moment from 'moment'

  export default {
    page: {title: "EINSTUDIO-Homework",meta: [{name: "description"}]},
    components: {
      Layout,PageHeader,ckeditor: CKEditor.component,vueDropzone: vue2Dropzone
    },data() {
      return {
        title: "HOMEWORK DETAILS",
        items: [{text: "Home",href:'/'},{text: "Homework",href:'/homeworks'}, {text: "Homework",active: true}],
        baseUrl:test.baseUrl,user:[],groups:[],fileUrl:test.fileUrl,
        editor: ClassicEditor,editorData:"", 
        editorConfig: {
          toolbar: { items: ['bold', 'italic','link','undo', 'redo','BulletedList', 'NumberedList', 'Blockquote'] }
        }, 
        selectedId:null,selectedParent:null,homeworkId:"",detailFile:[],postLink:"",postImage:"",postimageUrl:"",name:"",videoLink:"",postLength:0,
        uploadedFiles: [],fileindex:0,fileArray:[],
        dropzoneOptions: {
          url: "https://httpbin.org/post",
          thumbnailWidth: 200,
          maxFilesize: 25,
          timeout: 180000,
          parallelUploads: 100,
          addRemoveLinks: true,
          acceptedFiles: ".img,.jpg,.png,.gif,.pptx,.pdf,.xlsx,.docx",
          accept: this.checkDupl
        }
      };
    },created:function(){

      this.homeworkId=this.$route.params.id
      this.user = JSON.parse(localStorage.getItem('user'));
      this.loadData();
    },methods: {
        addPostData(){
         let loader = this.$loading.show();

         var formToPost = new FormData(document.getElementById("add-post-form"));
         formToPost.append("userId",this.user.id)
         formToPost.append("postNote",this.editorData)
         formToPost.append("isInGroup",true)
         formToPost.append("isApprove",true)
         formToPost.append("isOnlyTeacher",true)
         formToPost.append("homeworkId",this.homeworkId)

         for (let index = 0; index < this.uploadedFiles.length; index++) {
          const element = this.uploadedFiles[index];
          formToPost.append("files",element)
         }

         axios.post(`${this.baseUrl}/Homework/Post/Add`, formToPost, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.uploadedFiles=[];
          this.loadData();
          this.hideModal('modal-post');
        })
        .catch((error) => 
        {
          loader.hide();
          var html="";
          if(error.response.data.length>0)
          {
            for (let index = 0; index < error.response.data.length; index++) 
            {
              html +="<small>"+ error.response.data[index].message+"</small><hr>";
            }
          }
          else
          {
            html ="<p class='small'>"+ error.response.data.message+"</p>";
          }
          this.$swal("",html,"error");
        });
      },editPostData(){
         let loader = this.$loading.show();

         var formToPost = new FormData(document.getElementById("edit-file-form"));
         formToPost.append("userId",this.user.id)
         formToPost.append("postNote",this.editorData)
         formToPost.append("isInGroup",true)
         formToPost.append("isApprove",true)
         formToPost.append("isOnlyTeacher",true)
         formToPost.append("homeworkId",this.homeworkId)
         formToPost.append("id",this.id)

         for (let index = 0; index < this.uploadedFiles.length; index++) {
          const element = this.uploadedFiles[index];
          formToPost.append("files",element)
         }
         axios.put(`${this.baseUrl}/Homework/Post/Set`, formToPost, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.uploadedFiles=[];
          this.hideModal('modal-post-edit');
          this.loadData();
          this.initialData(this.id);
        })
        .catch((error) => 
        {
          loader.hide();
          var html="";
          if(error.response.data.length>0)
          {
            for (let index = 0; index < error.response.data.length; index++) 
            {
              html +="<small>"+ error.response.data[index].message+"</small><hr>";
            }
          }
          else
          {
            html ="<p class='small'>"+ error.response.data.message+"</p>";
          }
          this.$swal("",html,"error");
        });
      },addCommentData(){
         let loader = this.$loading.show();
       
            var formToPost = new FormData(document.getElementById("add-comment-form"));
            formToPost.append("userId",this.user.id)
            formToPost.append("explanation",this.editorData)
            formToPost.append("homeworkPostId",this.selectedId)
            formToPost.append("parentId",0)

         for (let index = 0; index < this.uploadedFiles.length; index++) {
          const element = this.uploadedFiles[index];
          formToPost.append("files",element)
         }
        // formToPost.append("parentId",null)

         axios.post(`${this.baseUrl}/Homework/Post/Conversation/Add`, formToPost, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.uploadedFiles=[];
          this.loadData();
          this.hideModal('modal-comment');
        })
        .catch((error) => 
        {
          loader.hide();
          var html="";
          if(error.response.data.length>0)
          {
            for (let index = 0; index < error.response.data.length; index++) 
            {
              html +="<small>"+ error.response.data[index].message+"</small><hr>";
            }
          }
          else
          {
            html ="<p class='small'>"+ error.response.data.message+"</p>";
          }
          this.$swal("",html,"error");
        });
      },editCommentData(){
         let loader = this.$loading.show();

         var formToPost = new FormData(document.getElementById("edit-comment-form"));

         formToPost.append("userId",this.user.id)
         formToPost.append("explanation",this.editorData)
         formToPost.append("status",true)
         formToPost.append("id",this.id)

         for (let index = 0; index < this.uploadedFiles.length; index++) {
          const element = this.uploadedFiles[index];
          formToPost.append("files",element)
         }
         axios.put(`${this.baseUrl}/Homework/Post/Conversation/Set`, formToPost, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.uploadedFiles=[];
          this.hideModal('modal-comment-edit');
          this.loadData();
          this.initialPostCommentData(this.id);
        })
        .catch((error) => 
        {
          loader.hide();
          var html="";
          if(error.response.data.length>0)
          {
            for (let index = 0; index < error.response.data.length; index++) 
            {
              html +="<small>"+ error.response.data[index].message+"</small><hr>";
            }
          }
          else
          {
            html ="<p class='small'>"+ error.response.data.message+"</p>";
          }
          this.$swal("",html,"error");
        });
      },addReplyData(){
         let loader = this.$loading.show();
         var formToPost = new FormData(document.getElementById("add-reply-form"));
         formToPost.append("userId",this.user.id)
         formToPost.append("explanation",this.editorData)
         formToPost.append("parentId",this.selectedParent)

         for (let index = 0; index < this.uploadedFiles.length; index++) {
          const element = this.uploadedFiles[index];
          formToPost.append("files",element)
         }

         axios.post(`${this.baseUrl}/Homework/Post/Conversation/Add`, formToPost, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.uploadedFiles=[];
          this.loadData();
          this.hideModal('modal-reply');
        })
        .catch((error) => 
        {
          loader.hide();
          var html="";
          if(error.response.data.length>0)
          {
            for (let index = 0; index < error.response.data.length; index++) 
            {
              html +="<small>"+ error.response.data[index].message+"</small><hr>";
            }
          }
          else
          {
            html ="<p class='small'>"+ error.response.data.message+"</p>";
          }
          this.$swal("",html,"error");
        });
      },loadData(){
          let loader =this.$loading.show();
          axios.get(this.baseUrl+"/Homework/GetById/"+this.$route.params.id, { headers:{ Authorization: `Bearer ${this.user.jwt}`}
          }).then((response)=>{
              this.groups= response.data;
              this.title= response.data.name;
              this.items[2].text=this.title;

              this.postLength= this.groups.homeworkPosts.length;
              loader.hide();
          }).catch((error)=>{
           const html ="<small>"+ error.response.data.message+"</small>";
           this.$swal("",html,"error");
          })
      },sendInfo(val,parent){
        this.selectedId = val;
        this.selectedParent=parent;
      },format_date(value){
         if (value) 
         {
           return moment(String(value)).format('MM.DD.YYYY HH:mm')
          }
      },deleteConfirm(id,type){
            this.$swal({ 
            title: "WARNING",
            text: "Are you sure? This data will be delete.",
            //type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Yes",
            cancelButtonText:"No",
            }).then((result) => {
                if (result.value) 
                {
                  if(type==0)
                  this.deleteData(id)
                  else if(type==3)
                  this.deleteGroupUser(id)
                  else if(type==4)
                  this.deletePostFile(id)
                  else if(type==5)
                  this.deletePostReply(id)
                }
            });
      },deleteData(id){
            let loader= this.$loading.show();
            axios.delete(`${this.baseUrl}/Homework/Post/Delete/`+id, {
                headers: { Authorization: `Bearer ${this.user.jwt}`}
            }).then((response) => 
            {
             loader.hide();
             this.$swal("",response.data.message, "success");
             this.loadData();
            })
            .catch((error) => 
            {
            loader.hide();
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
            });
      },deletePostFile(id){
            let loader= this.$loading.show();
            axios.delete(this.baseUrl+'/Homework/Post/File/Delete/'+id, {
                headers: { Authorization: `Bearer ${this.user.jwt}`}
            }).then((response) => 
            {
             loader.hide();
             this.$swal("",response.data.message, "success");
             this.loadData();
             this.initialPostCommentData(this.id);
            })
            .catch((error) => 
            {
            loader.hide();
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
            });
      },deletePostReply(id){
            let loader= this.$loading.show();
            axios.delete(this.baseUrl+'/Homework/Post/Conversation/Delete/'+id, {
                headers: { Authorization: `Bearer ${this.user.jwt}`}
            }).then((response) => 
            {
             loader.hide();
             this.$swal("",response.data.message, "success");
             this.loadData();
            })
            .catch((error) => 
            {
            loader.hide();
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
            });
      },dropzoneLoadedFile(file) {
        this.uploadedFiles.push(file);
      },checkDupl(file, done) {
        let v = this.$refs.FileDropzone;
        let files = v.getAcceptedFiles()
        let item = files.find( el => el.upload.filename === file.name )
        if (item) {
          done("duplicate")
        } else {
          done()
        }
      },initialPostData(id){
          axios.get(`${this.baseUrl}/Homework/Post/GetById/`+id, { headers: { Authorization: `Bearer ${this.user.jwt}`},
          }).then((response) => 
          {
            this.name= response.data.name;
            this.postimageUrl= this.fileUrl+'/files/images/homework/'+response.data.postImagePath+'/'+response.data.name+'/'+response.data.postImage;
            this.videoLink= response.data.explanation;
            this.homeworkId=response.data.homeworkId;
            this.detailFile=response.data.homeworkPostFiles;
            this.id=response.data.id;
            this.editorData=response.data.postNote;
            this.postLink=response.data.postLink;
            this.postImage=response.data.postImage;
          })
          .catch((error) => 
          {
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
          });
      },initialPostCommentData(id){
          axios.get(`${this.baseUrl}/Homework/Post/Conversation/GetById/`+id, { headers: { Authorization: `Bearer ${this.user.jwt}`},
          }).then((response) => 
          {
            this.name= response.data.name;
            this.homeworkId=response.data.homeworkId;
            this.detailFile=response.data.homeworkPostFiles;
            this.id=response.data.id;
            this.postLink=response.data.link;
            this.editorData= response.data.explanation
          })
          .catch((error) => 
          {
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
          });
      },showModal(modalid) {
        this.$root.$emit('bv::show::modal',modalid)
      },hideModal(modalid) {
        this.$root.$emit('bv::hide::modal',modalid)
      }
    }
  };
</script>
<style>
.cover-image{
  width: 100%;
    height: 100px;
    object-fit: cover;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
  
    <div class="row">
      <div class="col-12">
          <div class="jumbotron bg-white">
            <!-- <h3>{{ groups.name }}</h3> -->
                <div class="row">
                  <div class="col-lg-11">
                    <h3>{{groups.name}} </h3>
                  </div>
                  <div class="col-lg-1" v-b-tooltip title="Show/Hide Media">
                    <a type="button" v-b-toggle="'media-' + groups.id">
                      <i class="fa fa-2x fa-images float-right"></i>
                    </a>
                  </div>
                </div>
                <b-collapse :id="'media-'+groups.id">
                  <img v-if="(groups.coverImage!=null && groups.coverImage!='')" :src="fileUrl+'/files/images/homework/'+groups.basePath+'/'+groups.subPath+'/'+groups.coverImage" width="100%" height="100%" alt="" class="mb-3">
                  <hr class="my-4" v-if="(groups.videoLink!=null)">
                  <iframe v-if="(groups.videoLink!=null)" class="embed-responsive-item mb-3" :src="groups.videoLink" width="100%" height="400"></iframe>
                    <div class="row">
                      <div class="col-lg-3 col-xs-3 col-md-6 col-sm-12 mt-3" v-for="(item, index) in groups.homeworkFiles" :key="index">
                          <div class="card">
                                  <div class="card-body">
                                  <div class="d-flex">
                                      <div class="flex-1 overflow-hidden" style="width:100%">
                                          <img v-if="(item.fileExtension=='.jpg'| item.fileExtension=='.JPG'| item.fileExtension=='.png' | item.fileExtension=='.PNG')" :src="fileUrl+'/files/images/homework/'+item.basePath+'/'+item.subPath+'/files/'+item.name" class="cover-image" />
                                          <img v-if="(item.fileExtension=='.pdf')" :src="fileUrl+'/files/images/pdf.png'" class="cover-image" />
                                          <img v-if="(item.fileExtension=='.xlsx')" :src="fileUrl+'/files/images/excel.png'" class="cover-image" />
                                          <img v-if="(item.fileExtension=='.docx')" :src="fileUrl+'/files/images/word.png'" class="cover-image" />
                                          <img v-if="(item.fileExtension=='.pptx')" :src="fileUrl+'/files/images/ppt.png'" class="cover-image" />
                                      </div>
                                  </div>
                              </div>
                              <div class="card-body border-top py-3">
                                  <div class="text-truncate">
                                      <a  class="btn btn-sm btn-info" v-b-tooltip.hover title="Open" :href="(fileUrl+'/files/images/homework/'+item.basePath+'/'+item.subPath+'/files/'+item.name)" target="_blank"><i class="fa fa-eye"></i></a>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </b-collapse>

              <a :href="groups.link" target="_blank" class="mt-3">{{groups.link}}</a>
              <div v-html='groups.explanation'></div>

              <!--Comments-->
              <div class="d-flex flex-stack flex-wrap justify-content-between"  v-if="(user.rol=='admin'  | user.rol=='teacher')">
                <hr class="my-4">
                <div class="d-flex align-items-center py-1">
                  <a type="button"  class="btn btn-sm btn-outline btn-outline-dashed btn-outline-default px-2 py-1 me-2"  v-b-toggle="'collapse' + groups.id">
                    {{postLength}} Comments <i class="fa fa-chevron-down"></i>
                  </a>
                    <a type="button" class="btn btn-sm btn-info float-right ml-1" v-b-tooltip.hover title="Add Comment" v-b-modal.modal-post>
                    <i class="fa fa-envelope"></i></a>
                </div>
              </div>
              <hr>
              <b-collapse :id="'collapse'+groups.id">
                <div class="ansers mb-3"  v-for="(answer, index) in groups.homeworkPosts" :key="index">
                  <div class="row">
                    <div class="col-lg-2 col-xl-2 col-md-2 col-sm-12 text-center">

                      <div class="symbol symbol-35px me-2 mr-2">
                        <img :src="fileUrl+((answer.user.userImage!=null && answer.user.userImage!=' ')? ('/files/images/users/'+answer.user.userImage):'/files/images/logo-sm-light.png')"  alt="user" class="rounded-circle header-profile-user">
                    </div>

                      <div class="d-flex flex-column flex-grow-1 align-items-start justify-content-center">
                          <span class="text-gray-900 fs-7 fw-bold lh-1 mb-1"><a class="text-dark text-hover-primary" :href="'/users/profile/'+answer.user.id"><strong>{{answer.user.fullName}}</strong></a> <br><timeago class="text-muted fs-8 fw-bold lh-1 small font-italic" :datetime="answer.postDate" :auto-update="20" locale="tr-TR" /></span>
                      </div>

                    </div>
                    <div class="col-lg-10 col-xl-10 col-md-10 col-sm-12">
                      <div class="row">
                        <img v-if="(answer.postImage!=null && answer.postImage!='')" :src="fileUrl+'/files/images/homework/'+answer.postImagePath+'/'+answer.name+'/'+answer.postImage" width="100%" height="100%" alt="" class="mb-3">
                          <iframe v-if="(answer.explanation!=null)" class="embed-responsive-item mb-3" :src="answer.explanation" width="100%" height="400"></iframe>
           
                      </div>
                   
                        <div class="row mb-3">
                          <div v-html="answer.postNote"></div>
                          <a v-if="(answer.postLink!=null && answer.postLink!=' ')" :href="answer.postLink" target="_blank">{{ ' ( #'+answer.postLink+')' }}</a>
                        </div>
                                <!--sub files start-->
                                <div class="row">
                                  <div class="col-lg-3 col-xs-3 col-md-6 col-sm-12" v-for="(item2, index) in answer.homeworkPostFiles" :key="index">
                                      <div class="card">
                                              <div class="card-body">
                                              <div class="d-flex">
                                                  <div class="flex-1 overflow-hidden">
                                                      <img v-if="(item2.fileExtension=='.jpg'| item2.fileExtension=='.JPG'| item2.fileExtension=='.png' | item2.fileExtension=='.PNG')" :src="fileUrl+'/files/images/homework/'+item2.basePath+'/'+item2.subPath+'/'+item2.name" class="cover-image"/>
                                                      <img v-if="(item2.fileExtension=='.pdf')" :src="fileUrl+'/files/images/pdf.png'" class="cover-image"/>
                                                      <img v-if="(item2.fileExtension=='.xlsx')" :src="fileUrl+'/files/images/excel.png'" class="cover-image"/>
                                                      <img v-if="(item2.fileExtension=='.docx')" :src="fileUrl+'/files/images/word.png'" class="cover-image"/>
                                                      <img v-if="(item2.fileExtension=='.pptx')" :src="fileUrl+'/files/images/ppt.png'" class="cover-image"/>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="card-body border-top py-3">
                                              <div class="text-truncate">
                                                  <a  class="btn btn-sm btn-info" v-b-tooltip.hover title="Open" :href="(fileUrl+'/files/images/homework/'+item2.basePath+'/'+item2.subPath+'/'+item2.name)" target="_blank"><i class="fa fa-eye"> </i></a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <!--sub files end-->
                          <div class="row" v-for="(reply, index) in answer.homeworkPostConversation" :key="index">
                            <div class="col-12 border p-2">
                          
                              <img :src="fileUrl+((reply.user.userImage!=null && reply.user.userImage!=' ')? ('/files/images/users/'+reply.user.userImage):'/files/images/logo-sm-light.png')"  alt="user" class="rounded-circle header-profile-user">
                                <span class="text-gray-900 fs-7 fw-bold lh-1 mb-1"><a class="text-dark text-hover-primary" :href="'/users/profile/'+reply.user.id"><strong class="small">{{reply.user.fullName}}</strong></a>&nbsp; <timeago class="text-muted fs-8 fw-bold lh-1 small font-italic" :datetime="reply.replyDate" :auto-update="20" locale="tr-TR" /> 
                                </span>
                                <a v-if="(reply.link!=null && reply.link!=' ')" :href="reply.link" target="_blank">{{ ' (#'+reply.link+')' }}</a>
                                <div v-html="reply.explanation"></div>
                                
                                      <!--sub files start-->
                                      <div class="row">
                                        <div class="col-lg-3 col-xs-3 col-md-6 col-sm-12" v-for="(item2, index) in reply.homeworkPostFiles" :key="index">
                                            <div class="card">
                                                    <div class="card-body">
                                                    <div class="d-flex">
                                                        <div class="flex-1 overflow-hidden">
                                                            <img v-if="(item2.fileExtension=='.jpg'| item2.fileExtension=='.JPG'| item2.fileExtension=='.png' | item2.fileExtension=='.PNG')" :src="fileUrl+'/files/images/homework/'+item2.basePath+'/'+item2.subPath+'/'+item2.name" class="cover-image"/>
                                                            <img v-if="(item2.fileExtension=='.pdf')" :src="fileUrl+'/files/images/pdf.png'" class="cover-image"/>
                                                            <img v-if="(item2.fileExtension=='.xlsx')" :src="fileUrl+'/files/images/excel.png'" class="cover-image"/>
                                                            <img v-if="(item2.fileExtension=='.docx')" :src="fileUrl+'/files/images/word.png'" class="cover-image"/>
                                                            <img v-if="(item2.fileExtension=='.pptx')" :src="fileUrl+'/files/images/ppt.png'" class="cover-image"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body border-top py-3">
                                                    <div class="text-truncate">
                                                        <a  class="btn btn-sm btn-info" v-b-tooltip.hover title="Open" :href="(fileUrl+'/files/images/homework/'+item2.basePath+'/'+item2.subPath+'/'+reply.name+'/'+item2.name)" target="_blank"><i class="fa fa-eye"> </i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                      <!--sub files end-->
                                <!--reply to reply-->
                                <div v-for="(replyto, index) in reply.subConversation" :key="index">
                                    <div class="col-11 offset-1 border p-2 mb-3">
                                      <img :src="fileUrl+((reply.user.userImage!=null && reply.user.userImage!=' ')? ('/files/images/users/'+reply.user.userImage):'/files/images/logo-sm-light.png')"  alt="user" class="rounded-circle header-profile-user">
                                      <span class="text-gray-900 fs-7 fw-bold lh-1 mb-1"><a class="text-dark text-hover-primary" :href="'/userprofile/'+replyto.user.id"><strong class="small">{{replyto.user.fullName}}</strong></a> &nbsp;<timeago class="text-muted fs-8 fw-bold lh-1 small font-italic" :datetime="replyto.replyDate" :auto-update="20" locale="tr-TR" /></span>
                                      <a class="small" v-if="(replyto.link!=null && replyto.link!=' ')" :href="replyto.link" target="_blank">{{ ' (#'+replyto.link+')' }}</a>
                                      <div class="small" v-html="replyto.explanation"></div>
                                    
                                      
                                      <!--sub files start-->
                                      <div class="row">
                                        <div class="col-lg-3 col-xs-3 col-md-6 col-sm-12" v-for="(item2, index) in replyto.homeworkPostFiles" :key="index">
                                            <div class="card">
                                                    <div class="card-body">
                                                    <div class="d-flex">
                                                        <div class="flex-1 overflow-hidden">
                                                            <img v-if="(item2.fileExtension=='.jpg'| item2.fileExtension=='.JPG'| item2.fileExtension=='.png' | item2.fileExtension=='.PNG')" :src="fileUrl+'/files/images/homework/'+item2.basePath+'/'+item2.subPath+'/'+item2.name" class="cover-image"/>
                                                            <img v-if="(item2.fileExtension=='.pdf')" :src="fileUrl+'/files/images/pdf.png'" class="cover-image"/>
                                                            <img v-if="(item2.fileExtension=='.xlsx')" :src="fileUrl+'/files/images/excel.png'" class="cover-image"/>
                                                            <img v-if="(item2.fileExtension=='.docx')" :src="fileUrl+'/files/images/word.png'" class="cover-image"/>
                                                            <img v-if="(item2.fileExtension=='.pptx')" :src="fileUrl+'/files/images/ppt.png'" class="cover-image"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body border-top py-3">
                                                    <div class="text-truncate">
                                                        <a  class="btn btn-sm btn-info" v-b-tooltip.hover title="Open" :href="(fileUrl+'/files/images/homework/'+item2.basePath+'/'+item2.subPath+'/'+item2.name)" target="_blank"><i class="fa fa-eye"> </i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                      <!--sub files end-->
                                      <!--action buttons-->
                                      <a type="button" v-b-tooltip.hover title="Delete"  v-if="(user.rol=='admin' | user.id==replyto.user.id)" class="btn btn-sm btn-danger mt-3 ml-1"  @click="deleteConfirm(replyto.id,5)"><i class="fa fa-trash"></i></a>
                                      <a type="button" v-b-tooltip.hover title="Edit"  v-if="(user.rol=='admin' | user.id==replyto.user.id)"  v-b-modal.modal-comment-edit  class="btn btn-sm btn-warning mt-3 ml-1"  @click="initialPostCommentData(replyto.id)"><i class="fa fa-edit"></i></a>
                                      <!--action buttons-->
                                    </div>
                                      
                                </div>
                                  <!--reply to reply-->
                                <div style="width: 100%;">
                                  <a type="button"  v-b-modal.modal-reply  class="btn btn-sm btn-info mt-1 ml-1"  @click="sendInfo(answer.id,reply.id)"><i class="fa fa-paper-plane"></i></a>
                                  <a type="button" v-b-tooltip.hover title="Edit"  v-if="(user.rol=='admin'  | user.id==reply.user.id)"  v-b-modal.modal-comment-edit  class="btn btn-sm btn-warning mt-1 ml-1"  @click="initialPostCommentData(reply.id)"><i class="fa fa-edit"></i></a>
                                  <a type="button" v-b-tooltip.hover title="Delete"  v-if="(user.rol=='admin' | user.id==reply.user.id)"  class="btn btn-sm btn-danger mt-1 ml-1"  @click="deleteConfirm(reply.id,5)"><i class="fa fa-trash"></i></a>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <a type="button" v-b-tooltip.hover title="Delete" v-if="(user.rol=='admin' |  user.id==answer.user.id)"  class="btn btn-sm btn-danger float-right mt-3 ml-1"  @click="deleteConfirm(answer.id,0)"><i class="fa fa-trash"></i></a>
                      <a type="button" v-b-tooltip.hover title="Edit" v-if="(user.rol=='admin' |  user.id==answer.user.id)"   v-b-modal.modal-post-edit  class="btn btn-sm btn-warning float-right mt-3 ml-1"  @click="initialPostData(answer.id)"><i class="fa fa-edit"></i></a>
                      <a type="button" v-b-tooltip.hover title="Reply"  v-b-modal.modal-comment  class="btn btn-sm btn-info float-right mt-3 ml-1"  @click="sendInfo(answer.id,null)"><i class="fa fa-paper-plane"></i></a>
                      
                    </div>
                  </div>
                </div>
              </b-collapse>
              <!--comments-->
          </div>
      </div>
    </div>
    <b-modal id="modal-post" size="lg" title="New Post" title-class="font-18" hide-footer>
        <form @submit.prevent="addPostData()" id="add-post-form">
            <div class="form-group">
              <div class="row">
                <div class="col-lg-4 col-xl-4 col-md-6 col-sm-12">
                  <label id="tooltip-link"><i class="fa fa-info-circle"></i> Post Link</label>
                  <b-tooltip target="tooltip-link" triggers="hover">
                    <img src="@/assets/images/link-example.jpg" style="width:100%" />
                    <p>
                      Copy the place in the browser link section.
                    </p>
                  </b-tooltip>
                <input type="text" class="form-control" name="postLink" autocomplete="off"/>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-6 col-sm-12">
                <label id="tooltip-video-link"><i class="fa fa-info-circle"></i> Post Video Link</label>
                  <b-tooltip target="tooltip-video-link" triggers="hover">
                    <img src="@/assets/images/video-link-example.jpg" style="width:100%" />
                    <p>
                      Copy the content of the src link in the video sharing link.
                    </p>
                  </b-tooltip>
                <input type="text" class="form-control" name="explanation" autocomplete="off"/>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-6 col-sm-12">
                  <label>Cover Image</label>
                  <div class="input-group">
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="file" name="file" accept=".jpg,.png,.JPG,.PNG,.jpeg,.JPEG">
                      <label class="custom-file-label" for="file">Choose Image</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Post Files</label>
                    <vue-dropzone ref="FileDropzone" name="files" id="dropzone" :options="dropzoneOptions" v-on:vdropzone-success="dropzoneLoadedFile"></vue-dropzone>
            </div>

            <div class="form-group">
                <label>Explanation</label>
                <ckeditor v-model="editorData" :editor="editor" :config="editorConfig"></ckeditor>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning btn-sm"><i class="fa fa-plus"></i> ADD POST</button>
                </div>
            </div>
        </form>
    </b-modal>
    <b-modal id="modal-post-edit" size="lg" title="Edit Post Item" title-class="font-18" hide-footer>
        <form @submit.prevent="editPostData()" id="edit-file-form">
            <div class="form-group">
              <div class="row">
                <div class="col-lg-4 col-xl-4 col-md-6 col-sm-12">
                  <label id="tooltip-link"><i class="fa fa-info-circle"></i> Post Link</label>
                  <b-tooltip target="tooltip-link" triggers="hover">
                    <img src="@/assets/images/link-example.jpg" style="width:100%" />
                    <p>
                      Copy the place in the browser link section.
                    </p>
                  </b-tooltip>
                <input type="text" class="form-control" name="postLink" v-model="postLink" autocomplete="off"/>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-6 col-sm-12">
                <label id="tooltip-video-link"><i class="fa fa-info-circle"></i> Post Video Link</label>
                  <b-tooltip target="tooltip-video-link" triggers="hover">
                    <img src="@/assets/images/video-link-example.jpg" style="width:100%" />
                    <p>
                      Copy the content of the src link in the video sharing link.
                    </p>
                  </b-tooltip>
                <input type="text" class="form-control" name="explanation" v-model="videoLink" autocomplete="off"/>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-6 col-sm-12">
                  <label>Cover Image</label>
                  <div class="input-group">
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="file" name="file" accept=".jpg,.png,.JPG,.PNG,.jpeg,.JPEG">
                      <label class="custom-file-label" for="file">Choose Image</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-xs-3 col-md-6 col-sm-12" v-if="postImage!=null">
                <div class="card">
                  <div class="card-body">
                  <div class="d-flex">
                      <div class="flex-1 overflow-hidden" style="width:100%">
                      <img id="file" :src="postimageUrl" class="cover-image" />
                      </div>
                    </div>
                  </div>
                    <div class="card-body border-top py-3">
                        <div class="text-truncate">
                          <a type="button"  class="badge badge-info font-size-11"  v-b-tooltip.hover title="Cover image cannot be deleted. However, it can be changed."><i class="fa fa-info-circle"></i> Cover ımage</a>
                        </div>
                    </div>
                </div>
            </div>
              
            <div class="col-lg-3 col-xs-3 col-md-6 col-sm-12" v-for="(item, index) in detailFile" :key="index">
                <div class="card">
                        <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-1 overflow-hidden" style="width:100%">
                                <img v-if="(item.fileExtension=='.jpg'| item.fileExtension=='.JPG'| item.fileExtension=='.png' | item.fileExtension=='.PNG')" :src="fileUrl+'/files/images/homework/'+item.basePath+'/'+item.subPath+'/'+item.name" class="cover-image" />
                                <img v-if="(item.fileExtension=='.pdf')" :src="fileUrl+'/files/images/pdf.png'" class="cover-image" />
                                <img v-if="(item.fileExtension=='.xlsx')" :src="fileUrl+'/files/images/excel.png'" class="cover-image" />
                                <img v-if="(item.fileExtension=='.docx')" :src="fileUrl+'/files/images/word.png'" class="cover-image" />
                                <img v-if="(item.fileExtension=='.pptx')" :src="fileUrl+'/files/images/ppt.png'" class="cover-image" />
                            </div>
                        </div>
                    </div>
                    <div class="card-body border-top py-3">
                        <div class="text-truncate">
                            <a type="button"  class="badge badge-danger font-size-11" @click="DeleteConfirm(item.id,4)"><i class="fa fa-trash"></i> Delete</a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="form-group">
              <label>Post Files</label>
                    <vue-dropzone ref="FileDropzone" name="files" id="dropzoneeditpost" :options="dropzoneOptions" v-on:vdropzone-success="dropzoneLoadedFile"></vue-dropzone>
            </div>

            <div class="form-group">
                <label>Explanation</label>
                <ckeditor v-model="editorData" :editor="editor" :config="editorConfig"></ckeditor>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning btn-sm"><i class="fa fa-edit"></i> EDTT POST</button>
                </div>
            </div>
        </form>
    </b-modal>
    <b-modal id="modal-comment" size="lg" title="New Comment" title-class="font-18" hide-footer>
        <form @submit.prevent="addCommentData()" id="add-comment-form">
            <div class="form-group">
                <label>Commnet Link</label>
                <input type="text" class="form-control" name="link" autocomplete="off"/>
            </div>
            <div class="form-group">
              <label>Comment Files</label>
                    <vue-dropzone ref="FileDropzone" name="files" id="dropzonecomment" :options="dropzoneOptions" v-on:vdropzone-success="dropzoneLoadedFile"></vue-dropzone>
            </div>
            <div class="form-group">
                <label>Explanation (*)</label>
                <ckeditor v-model="editorData" :editor="editor"></ckeditor>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning btn-sm"><i class="fa fa-plus"></i> ADD COOMMENT</button>
                </div>
            </div>
        </form>
    </b-modal>
    <b-modal id="modal-comment-edit" size="lg" title="Edit Comment" title-class="font-18" hide-footer>
        <form @submit.prevent="editCommentData()" id="edit-comment-form">
            <div class="form-group">
                <label>Commnet Link</label>
                <input type="text" class="form-control" name="link" v-model="postLink" autocomplete="off"/>
            </div>
            <div class="row">
              <div class="col-lg-3 col-xs-3 col-md-6 col-sm-12" v-for="(item, index) in detailFile" :key="index">
                <div class="card">
                        <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-1 overflow-hidden" style="width:100%">
                                <img v-if="(item.fileExtension=='.jpg'| item.fileExtension=='.JPG'| item.fileExtension=='.png' | item.fileExtension=='.PNG')" :src="fileUrl+'/files/images/homework/'+item.basePath+'/'+item.subPath+'/'+item.name" class="cover-image" />
                                <img v-if="(item.fileExtension=='.pdf')" :src="fileUrl+'/files/images/pdf.png'" class="cover-image" />
                                <img v-if="(item.fileExtension=='.xlsx')" :src="fileUrl+'/files/images/excel.png'" class="cover-image" />
                                <img v-if="(item.fileExtension=='.docx')" :src="fileUrl+'/files/images/word.png'" class="cover-image" />
                                <img v-if="(item.fileExtension=='.pptx')" :src="fileUrl+'/files/images/ppt.png'" class="cover-image" />
                            </div>
                        </div>
                    </div>
                    <div class="card-body border-top py-3">
                        <div class="text-truncate">
                            <a type="button"  class="badge badge-danger font-size-11" @click="DeleteConfirm(item.id,4)"><i class="fa fa-trash"></i> Delete</a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="form-group">
              <label>Comment Files</label>
                    <vue-dropzone ref="FileDropzone" name="files" id="dropzonecommentedit" :options="dropzoneOptions" v-on:vdropzone-success="dropzoneLoadedFile"></vue-dropzone>
            </div>
            <div class="form-group">
                <label>Explanation (*)</label>
                <ckeditor v-model="editorData" :editor="editor" :config="editorConfig"></ckeditor>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning btn-sm"><i class="fa fa-edit"></i> EDIT COOMMENT</button>
                </div>
            </div>
        </form>
    </b-modal>
    <b-modal id="modal-reply" size="lg" title="New Comment" title-class="font-18" hide-footer>
        <form @submit.prevent="addReplyData()" id="add-reply-form">
            <div class="form-group">
                <label>Commnet Link</label>
                <input type="text" class="form-control" name="link" autocomplete="off"/>
            </div>
            <div class="form-group">
              <label>Comment Files</label>
                    <vue-dropzone ref="FileDropzone" name="files" id="dropzonereply" :options="dropzoneOptions" v-on:vdropzone-success="dropzoneLoadedFile"></vue-dropzone>
            </div>
            <div class="form-group">
                <label>Explanation (*)</label>
                <ckeditor v-model="editorData" :editor="editor" :config="editorConfig"></ckeditor>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning btn-sm"><i class="fa fa-plus"></i> ADD COOMMENT</button>
                </div>
            </div>
        </form>
    </b-modal>
  </Layout>
</template>